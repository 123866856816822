
:root {
  --color-primary: #14213D;

  --color-accent: #ED6A5A;
  --color-accent-2: #97443A;
  --color-accent-3: #F3A096;

  --color-text: #39393A;

  --color-white: #FAFAFA;
  --color-grey-1: #F5F5F5;
  --color-grey-2: #F1F1F1;
  --color-grey-3: #ECECEC;
  --color-grey-4: #E8E8E8;
  --color-grey-5: #D2D2D2;

  --color-linen: #FCF8F4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, .h1 {
  margin: 0;
}

a {
  color: var(--color-accent);
  border-bottom: 3px solid transparent;
  text-decoration: none;
  transition: 160ms cubic-bezier(0.5, 0, 0.1, 1);
}

a:hover, a:focus {
  color: var(--color-accent-2);
  border-bottom-color: var(--color-accent-2);
}

body {
  background-color: var(--color-linen);
  background: url(assets/dot-grid.png) repeat;
  color: var(--color-text);
  font-size: 16px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

header {
  height: 64px;
  width: 100%;
  position: relative;
  background-color: var(--color-primary);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.16);
}

.header-wrap {
  max-width: 860px;
  margin: 0 auto;
  display: flex;
}

h1 {
  color: var(--color-white);
  flex-grow: 1;
  line-height: 64px;
}

nav {
  flex-grow: 1;
  margin-right: 2%;
  text-align: right;
}

nav a {
  display: inline-block;
  line-height: 61px;
  padding: 0 16px;
  margin-left: 8px;
  height: 61px;
  font-weight: 700;
  font-size: 14px;
}

nav a.is-current {
  border-bottom-color: var(--color-accent);
}

nav a:hover, a:focus {
  outline: none;
  color: var(--color-accent-3);
    border-bottom-color: var(--color-accent-3);
}

nav a.is-current:hover, nav a.is-current:focus {
  border-bottom-color: var(--color-accent);
  color: var(--color-accent);
  cursor: default;
}

.container {
  padding: 24px;
  max-width: 860px;
  margin: 1vh auto 0;
}

.upload-box {
  margin-top: 10vh;
  border: 5px dashed var(--color-grey-5);
  background: var(--color-grey-1);
  min-height: 250px;
  transition: 240ms cubic-bezier(0.5, 0, 0.1, 1);
  opacity: 0.7;
}

.upload-box.minimized {
  margin-top: 0vh;
  min-height: 50px;
}

.instruction, .spinner {
  width: 100%;
  text-align: center;
  opacity: 0.7;
  font-weight: 700;
  padding: 12vh 0;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
}

.spinner {
  display: none;
}

.upload-box.loading .spinner {
  display: block
}

.upload-box.loading .instruction {
  display: none
}

.upload-box.minimized .instruction {
  padding: 1vh 0;
}

.upload-box:hover {
  cursor: pointer;
  opacity: 1;
  background: var(--color-grey-2);
  border-color: var(--color-primary);
  box-shadow: 0 16px 24px 0 rgba(0,0,0,0.12);
}

.result-box {
  border: 5px solid transparent;
  background: transparent;
  min-height: 250px;
  padding: 24px;
  transition: 240ms cubic-bezier(0.5, 0, 0.1, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-box .target {
  max-width: 100%;
}

.renderer {
  position: absolute;
  top: 0;
  left: 0;
}

.images {
  max-width: 100%;
}

.images img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  transition: 70ms cubic-bezier(0.5, 0, 0.1, 1);
  /** you can remove this **/
  box-shadow: 0 16px 24px 0 rgba(0,0,0,0.24);
}

.images img:hover {
  cursor: grab;
  opacity: 0.8;
}

footer {
  font-size: 14px;
  opacity: 0.6;
  margin: 20vh auto 2vh;
  text-align: center;
}
